<template>
    <v-container fluid class="pa-0 ma-0 fill-height text-left">
        <v-row justify="start">
            <v-col cols="12">
                <v-card class="d-flex fill-height fluid pa-0 ma-0" tile width="100%">
                    <v-scroll-x-transition appear>
                        <v-img
                                src="@/assets/images/hospital-angeles.jpg"
                                transition="slide-x-transition"
                                position="top"
                                width="100%"
                                height="300"
                                max-height="300">
                            <v-container fluid class="fill-height justify-center align-end bottom-gradient">
                                <v-row align="center" justify="center">
                                    <v-col>
                                        <div class="white--text pb-10">
                                            <span   class="pl-1 pl-md-8 text-h5 text-md-h4 text-uppercase font-weight-black"
                                                    style="text-shadow: 2px 2px 10px black">
                                                Nuestro Catálogo confiable de Hospitales
                                            </span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-img>
                    </v-scroll-x-transition>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <div class="boxdivider"></div>
        </v-row>
        <v-row justify="center" align="center">
            <div class="fill-height container--fluid mt-8 mb-8 mx-5" style="width: 100%">
                <v-container fluid class="fill-height">
                    <v-row justify="start" align="center">
                        <v-col
                            v-for="(item, index) in hospitals"
                            :key="index"
                            class="d-flex child-flex"
                            cols="12" sm="6" md="4">
                            <!-- v-if="item.pictures[0] != null" -->
                            <v-card hover class="rounded-lg">
                                <!--:src="require(`@/${item.pictures[0].url}`)"-->
                                <v-img
                                    :src="item.pictures[0] != null ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                    :lazy-src="require('@/assets/images/no-image.jpg')"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                    aspect-ratio="1"
                                    height="350"
                                    class="rounded-lg white--text align-end justify-start elevation-2"
                                    @click="showProfile(item.id)">
                                    <v-btn v-model="selectedId"
                                           dark
                                           plain
                                           text
                                           elevation="0"
                                           class="ma-2"
                                           v-on:click="showProfile(item.id)">
                                        <!--
                                        to="`/books/${book.id}`"
                                        :to="{ path: 'register', query: {plan: 'private'}}" ==> resulting in /register?plan=private
                                        :to="{name: 'HospitalProfile', params: {id: item.id}}"
                                        -->
                                        <span class="text-uppercase font-weight-bold" v-text="item.name"></span>
                                    </v-btn>

                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5">
                                            </v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-row>
        <v-row justify="center" class="ma-10" v-if="hospitals.length === 0">
            <div>
                No hay Hospitales en el catálogos...
            </div>
        </v-row>
    </v-container>
</template>

<script>
    import hospitalsService from '@/providers/HospitalsService';

    export default {
        name: "HospitalsComponent",
        data: () => ({
            selectedId: 0,
            hospitals: [],
            transparent: "rgba(255, 255, 255, 0)",
        }),
        methods: {
            getHospitals() {
                hospitalsService.getViewList().then(record => {
                    this.hospitals = record.value;
                    // console.log("Hospitales: ", this.hospitals);
                });
            },
            showProfile(idHospital) {
                this.selectedId = idHospital;
                this.$router.push({
                    name: "HospitalProfile",
                    params: {
                        id: idHospital,
                    }
                });
            }
        },
        mounted() {
            this.hospitals = [];
            this.getHospitals();
        },
        // :src="require(`@/assets/images/hospitals/hospital${n}.jpg`)"
        // :lazy-src="require('@/assets/images/no-image.jpg')"
    };
</script>

<style scoped>
    /* body {
        @apply antialiased text-green-900 bg-green-50;
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    }  */
    .bottom-gradient {
        background-image: linear-gradient(
                to bottom,
                rgba(163, 153, 248, 0.295) 0%,
                rgba(101, 99, 248, 0.699) 100%
        );
    }
    #my-lax .v-parallax__content {
        background: linear-gradient(45deg, black, transparent);
    }
    .v-btn {
        text-transform: none !important;
    }
    .blend-opacity {
        opacity: 0.5;
        transition: opacity 0.4s ease-in-out;
        background-color: black;
        background-blend-mode: normal;
    }
    .blend-opacity:hover {
        opacity: 0.9;
        transition: opacity 0.4s ease-in-out;
        background-color: black;
        background-blend-mode: darken;
    }
    .boxdivider {
        background-color: transparent;
        width: 100px;
        height: 50px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        border-bottom-color: blueviolet;
        border-bottom-style: solid;
        display: block;
    }
</style>
